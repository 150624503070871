.footer {
  padding: 60px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-top: 100px;
  @include tablet {
    margin-top: 40px;
  }
  @include small-tablet {
    margin-top: 0;
    padding-bottom: 15px;
  }
  @include mobile {
    margin-top: 0;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 200px 280px 1fr;
    gap: 30px;
    @include small-tablet {
      grid-template-columns: 1fr 1fr;
    }
    @include mobile {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 24px;
    }
  }
  &__column {
    &.column-big {
      @include small-tablet {
        display: contents;
      }
      .footer__logo {
        @include mobile {
          flex: 0 0 100%;
        }
        .logo__img {
          @include mobile {
            width: 107px;
          }
        }
      }
      .footer__privacy {
        margin-top: 40px;
        font-size: 12px;
        line-height: 170%;
        display: grid;
        gap: 4px;
        @include small-tablet {
          order: 3;
          grid-area: 3 / 1 / 4 / 3;
          display: flex;
          gap: 24px;
        }
        @include mobile {
          flex: 0 0 100%;
          margin-top: 0;
          flex-wrap: wrap;
          justify-content: center;
          gap: 7px;
        }
        .privacy__link {
          display: block;
          color: var(--greylight);
          font-weight: 400;
          @include mobile {
            text-decoration: underline;
            flex: 0 0 100%;
            text-align: center;
          }
          &:hover {
            color: var(--grey)
          }
        }
      }
    }
    &.column-contact {
      @include small-tablet {
        order: 1;
      }
      @include mobile {
        flex: 0 0 35%;
      }
      .contact__list {
        display: grid;
        gap: 6px;
      }
    }
    &.column-social {
      @include small-tablet {
        order: 2;
      }
      @include mobile {
        flex: 0 0 100%;
      }
      .social__list {
        display: flex;
        gap: 14px;
        @include mobile {
          width: 100%;
          justify-content: center;
        }
      }
      .social__title {
        @include mobile {
          display: none;
        }
      }
    }
    &.column-address {
      @include mobile {
        flex: 0 0 50%;
      }
    }
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #8c9dadbf;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__wrapper {
    position: relative;
    flex: 0 0 580px;
    @include mobile {
      flex: 0 0 95%;
      margin: 0 15px;
    }
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 17px;
    padding: 4px;
    z-index: 2;
    stroke: var(--greylight);
    @include small-tablet {
      top: 10px;
      right: 22px;
    }
    @include mobile {
      right: 10px;
    }
    &:hover svg {
      stroke: var(--main)
    }
  }
  &__form {
    &.form__container {
      background-color: #ffffff;
      position: relative;
      @include tablet {
        gap: 16px;
      }
      @include small-tablet {
        padding: 40px 20px;
      }
      .form__group {
        .form__checkbox-label {
          @include tablet {
            font-size: 14px;
          }
        }
        .form__input {
          @include tablet {
            margin-top: 2px;
          }
        }
      }
    }
    .form__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: var(--text);
      margin: 0;
      max-width: 500px;
      @include tablet {
        font-size: 20px;
      }
    }
    .form__text {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: var(--text);
      margin-top: -10px;
      @include tablet {
        font-size: 16px;
      }
    }
  }
}
.canihave_widget {
   z-index: 0;
}
