.hero {
  @include mobile {
    margin-top: 10px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 21px;
    @include small-tablet {
      align-items: center;
    }
    @include mobile {
      display: grid;
      gap: 3px;
    }
  }
  &__info {
    flex: 0 0 480px;
    height: min-content;
    margin-top: 76px;
    @include desktop {
      flex: 0 0 410px;
    }
    @include small-tablet {
      margin-top: 0;
    }
  }
  &__logo {
    font-weight: 900;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    span {
      color: var(--main)
    }
  }
  &__title {
    margin-top: 12px;
  }
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    margin-top: 12px;
    @include mobile {
      font-size: 16px;
    }
  }
  &__btns {
    display: flex;
    gap: 10px;
  }
  &__btn {
    margin-top: 32px;
    @include mobile {
      margin-top: 24px;
    }
  }
}


.solution {
  &__wrapper {
    border: 2px dashed #b0d1ff;
    border-radius: 16px;
    padding: 60px;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    @include mobile {
      grid-template-columns: 1fr;
      padding: 24px;
      gap: 24px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: var(--grey);
    margin: 0;
    @include small-tablet {
      font-size: 20px;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: var(--grey);
    @include small-tablet {
      font-size: 16px;
    }
  }
}

.effect {
  &__title {
    width: 290px;
    @include mobile {
      width: 190px;
    }
    span {
      color: var(--main);
    }
  }
  &__container {
    width: 192px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg width='168' height='40' viewBox='0 0 168 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9987 8.33337V31.6667M19.9987 31.6667L31.6654 20M19.9987 31.6667L8.33203 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M83.9987 8.33337V31.6667M83.9987 31.6667L95.6654 20M83.9987 31.6667L72.332 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M147.999 8.33337V31.6667M147.999 31.6667L159.665 20M147.999 31.6667L136.332 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    @include mobile {
      display: none;
    }
  }
  &__wrapper {
    &.wrapper-upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &.wrapper-list {
      margin-top: 40px;
      @include small-tablet {
        margin-top: 20px;
      }
      .effect__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        @include medium-tablet {
          grid-template-columns: repeat(2, 1fr);
        }
        @include mobile {
          grid-template-columns: 1fr;
          gap: 8px;
        }
        .list__item {
          border-radius: 8px;
          padding: 24px;
          background-color: var(--bggrey);
          .item__icon {
            @include mobile {
              display: none;
            }
          }
          .item__subtitle {
            font-weight: 600;
            font-size: 20px;
            color: var(--text);
            line-height: 130%;
            margin: 40px 0 0 0;
            @include medium-tablet {
              font-size: 18px;
              margin-top: 20px;
            }
            @include mobile {
              font-size: 16px;
              margin-top: 0;
            }
          }
          .item__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            display: block;
            color: var(--greylight);
            margin-top: 10px;
            @include small-tablet {
              font-size: 14px;
            }
            i {
              display: block;
              margin-top: 8px;
            }
          }
        }
      }

    }
  }
}
.video {
  &__wrapper {

  }
  &__title {
    text-align: center;
    @include mobile {
      text-align: left;
      width: 240px;
    }
  }
  &__container {

    background-color: var(--bggrey);
    border-radius: 8px;
    padding: 60px;
    @include small-tablet {
      padding: 40px 20px;
    }
    @include mobile {
      padding: 16px;
    }
    iframe {
      border-radius: 10px;
      overflow: hidden;
      height: 500px;
      width: 889px;
      margin: 40px auto 0 auto;
      display: block;
      @include small-tablet {
        width: 100%;
        height: 380px;
        margin-top: 24px;
      }
      @include mobile {
        height: 200px;
      }
    }
  }
}

.how {
  &__wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 380px);
    position: relative;
    @include medium-tablet {
      grid-template-columns: 1fr;
      gap: 16px;
    }
    @include mobile {
      gap: 8px;
      justify-items: center;
    }
  }
  &__column {
    &.column-start {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include medium-tablet {
        flex-direction: row;
        gap: 16px;
      }
      @include mobile {
        flex-direction: column;
      }
    }
    &.column-query {
      padding-top: 185px;
      @include medium-tablet {
        padding-top: 0;
        width: 778px;
      }
      @include small-tablet {
        width: 698px;
      }
      @include mobile {
        width: 100%;
      }
    }
    &.column-end {
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include medium-tablet {
        flex-direction: row;
      }
      @include mobile {
        flex-direction: column;
        width: 100%;
      }
    }
  }
  &__item {
    border-radius: 8px;
    padding: 24px;
    background-color: var(--bggrey);
    @include medium-tablet {
      flex: 0 0 380px;
    }
    @include small-tablet {
      flex: 0 0 340px;
    }
    @include mobile {
      flex: 0 0 auto;
    }
    &.item-pt {
      padding-top: 0;
    }
    .item {
      &__subtitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #101828;
        margin: 24px 0 0 0;
        @include small-tablet {
          font-size: 16px;
        }
      }
      &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #475467;
        margin-top: 8px;
        @include small-tablet {
          font-size: 14px;
        }
      }
    }
  }
  &__arrow {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 60px;
    background-color: var(--bggrey);
    border: 4px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    @include medium-tablet {
      transform: rotate(90deg);
    }
    @include mobile {
      position: relative;
    }
    &.arrow-first {
      top: 299px;
      left: 363px;
      @include medium-tablet {
        top: 460px;
        left: 364px;
      }
      @include small-tablet {
        left: 323px;
        top: 418px;
      }
      @include mobile {
        left: 0;
        top: 0;
      }
    }
    &.arrow-second {
      left: 768px;
      top: 509px;
      @include medium-tablet {
        top: 930px;
        left: 364px;
      }
      @include small-tablet {
        left: 323px;
        top: 874px;
      }
      @include mobile {
        left: 0;
        top: 0;
      }
    }
  }
}
.category {
  &__title {
    text-align: center;
  }
  &__btn {
    margin: 24px auto 0 auto;
  }
  &__wrapper {
    margin-top: 40px;
    @include small-tablet {
      margin-top: 24px;
    }
    .sliders__item {
      &.item-right {
        margin-top: 18px;
        @include mobile {
          margin-top: 16px;
        }
      }
    }
    .slider__item {
      width: auto;
      display: flex;
      .item__wrapper {
        flex: 0 0 100%;
        width: 220px;
        background-color: #F2F4F6;
        height: 220px;
        display: flex;
        border-radius: 10px;
        align-items: center;
        justify-items: center;
        overflow: hidden;
        @include tablet {
          height: 200px;
          width: 200px;
        }
        @include medium-tablet {
          height: 180px;
          width: 180px;
        }
        @include small-tablet {
          height: 140px;
          width: 140px;
        }
        .item__img {
          margin: 0 auto;
          @include tablet {
            max-width: 80%;
          }
        }
      }
    }
  }
  .swiper-wrapper {
    width: 100%;
  }
}



.services {
  &__title {
    text-align: center;
  }
  &__info {
    margin-top: 16px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: var(--text);
    @include small-tablet {
      font-size: 16px;
      margin-top: 12px;
    }
  }
  &__wrapper {

  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 56px;
    @include small-tablet {
      gap: 8px;
      margin-top: 18px;
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
    .list__column {
      display: grid;
      gap: 20px;
      @include small-tablet {
        gap: 8px;
      }
    }
  }
  &__item {
    border-radius: 16px;
    padding: 40px;
    background-color: #edf4ff;
    //display: flex;
    //flex-direction: column;
    &.item-pt {
      padding-top: 0;
      .item__img {
        margin-bottom: 40px;
        @include small-tablet {
          margin-bottom: 16px;
        }
      }
    }
    &.item-pb {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      .item__picture {
        margin-top: auto;
        display: flex;
        align-items: flex-end;
        @include mobile {
          margin-top: 16px;
        }
      }
    }
    .item {
      &__img {

      }
      &__use {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-transform: uppercase;
        color: var(--main);
      }
      &__subtitle {
        margin-bottom: 8px;
      }
      &__wrapper {
        display: flex;
        gap: 16px;
        margin-top: 24px;
        @include small-tablet {
          margin-top: 16px;
        }
      }
      &__text {
        color: #666d6d;
      }
    }
    .item__img {
    }
  }
}
.tasks {
  &__title {
    text-align: center;
    @include mobile {
      text-align: left;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 40px;
    @include small-tablet {
      grid-template-columns: repeat(2,1fr);
      margin-top: 24px;
      gap: 16px;
    }
  }
  &__item {
    padding: 24px;
    border: 1px solid #edf4ff;
    border-radius: 8px;
    @include mobile {
      padding-top: 73px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%230260E8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 24px 24px;
    }
    &:nth-child(1) {
      .item__subtitle {
        max-width: 200px;
      }
    }
    .item__img {
      @include mobile {
        display: none;
      }
    }
    .item__subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
      color: #101828;
      margin: 0;
      @include small-tablet {
        font-size: 16px;
      }
    }
  }
  &__btn {
    margin: 24px auto 0 auto;
  }
}
.advantages {
  &__title {
    text-align: center;
    @include mobile {
      text-align: left;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 32px;
    @include small-tablet {
      grid-template-columns: 1fr;
    }
    @include mobile {
      gap: 10px;
    }
  }
  &__column {
    &.column-opportunity {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      @include  mobile {
        grid-template-columns: 1fr;
        gap: 10px;
      }
      .opportunity__item {
        border-radius: 8px;
        padding: 24px;
        background-color: var(--bggrey);
        @include mobile {
          display: grid;
          grid-template-columns: 47px 1fr;
          column-gap: 10px;
          padding: 24px 10px;
        }
        .item__text {
          margin-top: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #475467;
          @include mobile {
            display: flex;
            align-items: flex-start;
            margin-top: 0;
            grid-area: 2 / 2 / 3 / 3;
          }
        }
        .item__icon {
          @include mobile {
            grid-area: 1 / 1 / 3 / 2;
          }
        }
        .item__subtitle {
          @include mobile {
            margin-top: 0;
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
    &.column-analytics {
      padding: 40px 40px 0 40px;
      border-radius: 8px;
      background-color: var(--lightmain);
      @include mobile {
        padding: 20px 10px 0 10px;
      }
      .analytics__item {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .item__subtitle {
        margin-top: 0;
      }
      .item__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: #0f1010;
        margin-top: 8px;
        @include small-tablet {
          font-size: 14px;
        }
      }
      .item__wrapper {
        display: flex;
        gap: 16px;
        margin-top: 24px;
        margin-bottom: 40px;
      }
      .item__picture {
        margin-top: auto;
        display: flex;
        align-items: flex-end;
      }
    }
    .item__subtitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
      color: #101828;
      margin: 40px 0 0 0;
      @include small-tablet {
        font-size: 16px;
      }
    }
  }
}
.calc {
  padding: 80px 0;
  background-color: var(--lightmain);
  @include small-tablet {
    padding: 40px 0;
  }
  &__wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    @include small-tablet {
      grid-template-columns: 330px 1fr;
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    color: #0f1010;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    margin: 0;
    @include small-tablet {
      font-size: 24px;
    }
    .title__blue {
      color: var(--main);
      display: block;
    }
  }
  &__container {
    position: relative;
  }
  &__step {
    &.step-info {
      .form {
        display: grid;
        gap: 16px;
        @include small-tablet {
          padding-top: 0;
        }
        &__row {
          width: 100%;
          outline: 1px solid var(--underline);
          border-radius: 8px;
          //padding: 23px 14px 5px 14px;
          position: relative;
          display: flex;
          &.focus {
            outline: 1px solid var(--main);
            .form__label {
              font-size: 12px;
              color: var(--main);
              top: 25%;
            }
          }
        }
        &__input {
          width: 100%;
          height: 100%;
          padding: 20px 14px 8px 14px;
          font-size: 16px;
          &:focus {
            outline: none;
          }
        }
        &__label {
          font-weight: 400;
          font-size: 16px;
          color:  #666d6d;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          transition: .3s;
        }
        &__approve {
          @include mobile {
            margin-top: 10px;
          }
        }
      }
    }
    &.step-final {
      border-radius: 12px;
      padding: 24px;
      border: 1px dashed  #b0d1ff;
      position: absolute;
      background-color: var(--lightmain);
      left: -5px;
      top: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      &.visible {
        opacity: 1;
        visibility: visible;
      }
      .step__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        margin: 0;
        color: var(--text);
        @include small-tablet {
          font-size: 20px;
        }
      }
      .step__result {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: var(--main);
        margin-top: 4px;
      }
      .step__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        color: #000;
        margin-top: 24px;
        @include small-tablet {
          font-size: 16px;
        }
      }
      .step__btn {
        margin-top: 24px;
      }
    }
    &.step-load {
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      background-color: var(--lightmain);
      left: -5px;
      top: -5px;
      z-index: -1;
      &.visible {
        z-index: 3;
      }
    }
  }

}

.price {
  background-color: var(--bggrey);
  margin-top: 0;
  padding: 66px 0 80px 0;
  @include small-tablet {
    padding-top: 40px;
  }
  .tariff {
    &__title {
      text-align: center;
    }
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 46px repeat(4, 1fr);
      gap: 16px;
      margin-top: 40px;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color:  #0f1010;
      @include small-tablet {
        font-size: 14px;
      }
      @include mobile {
        display: grid;
        grid-template-columns: 120px 1fr;
        grid-template-rows: 34px repeat(4, 1fr);
        margin-top: 28px;
      }
    }
    &__row {
      display: contents;
      @include mobile {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        grid-area: 1 / 1 / 2 / 3;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 10px;
      background-color: #fff;
      text-align: center;
      @include small-tablet {
        padding: 12px 10px;
      }
      @include mobile {
        display: none;
        padding: 6px 10px;
      }
      &.active {
        @include mobile {
          display: flex;
        }
      }
      &.item-full {
        grid-area: 3 / 2 / 4 / 5;
        @include mobile {
          display: flex;
          grid-area: 3 / 2 / 4 / 3;
        }
      }
      &.item-blue {
        border: 2px solid  #b0d1ff;
        @include mobile {
          border: none;
        }
      }
      &.item-title {
        padding: 10px;
        font-weight: 500;
        background-color: #dbe9ff;
        @include mobile {
          display: flex;
        }
        &:nth-child(3) {
          grid-area: 2 / 1 / 3 / 2;
        }
        &:nth-child(7) {
          grid-area: 3 / 1 / 4 / 2;
        }
        &:nth-child(9) {
          grid-area: 4 / 1 / 5 / 2;
        }
        &:nth-child(13) {
          grid-area: 5 / 1 / 6 / 2;
        }
      }
      &.item-toggle {
        font-weight: 500;
        @include mobile {
          display: flex;
          border-radius: 30px;
          border: 1px solid #dbe9ff;
          color: #666d6d;
          flex: 0 0 60px;
        }
        &.choose {
          @include mobile {
            background-color: #edf4ff;
            border: 1px solid #edf4ff;
            color: var(--main);
          }
        }
      }
      &.item-hidden {
        background-color: #fff;
        @include mobile {
          display: none;
        }
      }
    }
  }
  .rate {
    margin-top: 64px;
    @include mobile {
      margin-top: 40px;
    }
    &__title {
      text-align: center;
    }
    &__wrapper {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
      @include medium-tablet {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mobile {
        margin-top: 24px;
        grid-template-columns: 1fr;
      }
    }
    &__item {
      border-radius: 16px;
      padding: 32px 20px;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      background-color: var(--lightmain);
      box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
      display: flex;
      flex-direction: column;
      &:nth-child(4) {
        .item__more {
          opacity: 0;
          visibility: hidden;
          @include mobile {
            display: none;
          }
        }
      }
      &.active {
        transition: .3s;
        .item__list {
          .list__item:nth-child(n + 4) {
            @include medium-tablet {
              display: flex;
            }
          }
        }
      }
      .item__list {
        display: grid;
        gap: 16px;
        margin-top: 32px;
        margin-bottom: 40px;
        @include medium-tablet {
          margin-bottom: 16px;
        }
        @include mobile {
          margin-bottom: 0;
          margin-top: 24px;
        }
        .list__item {
          color: #475467;
          position: relative;
          display: flex;
          gap: 5px;
          @include medium-tablet {
            width: 100%;

          }
          &:nth-child(n + 4) {
            @include medium-tablet {
              display: none;
            }
          }
          svg {
            fill: var(--main);
            flex: 0 0 20px;
            height: 20px;
          }
        }
      }
      .item__subtitle {
        margin: 0;
        text-align: center;
      }
      .item__more {
        display: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-decoration: underline;
        color: var(--main);
        margin-top: auto;
        @include medium-tablet {
          display: block;
          margin-bottom: 24px;
        }
        @include mobile {
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
      .item__btn {
        margin-top: auto;
        border-radius: 68px;
        display: flex;
        justify-content: center;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        @include medium-tablet {
          margin: 24px auto 0 auto;
          width: 313px;
        }
        @include small-tablet {
          width: 248px;
        }
        @include mobile {
          margin-top: 24px;

        }
      }
    }
  }
}
.form {
  @include small-tablet {
    background-color: var(--lightmain);
    margin-top: 0;
    padding-top: 60px;
  }
  @include mobile {
    padding-top: 40px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @include medium-tablet {
      grid-template-columns: 446px 1fr;
    }
    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }
  &__info {
    margin-top: 40px;
    @include small-tablet {
      width: 550px;
      margin: 0 auto;
      text-align: center;
    }
    @include mobile {
      width: 100%;
    }
    .info__logo {
      font-weight: 900;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--grey);
      .blue {
        color: var(--main)
      }
    }
    .info__title {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      color: var(--text);
      margin: 12px 0 0 0;
      @include small-tablet {
        font-size: 24px;
        line-height: 120%;
      }
    }
    .info__text {
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      margin-top: 14px;
      @include small-tablet {
        font-size: 16px;
      }
      @include mobile {
        display: none;
      }
    }
  }
&__container {
  border-radius: 10px;
  padding: 52px 32px;
  background-color: var(--lightmain);
  display: grid;
  gap: 24px;
  position: relative;
  @include small-tablet {
    padding: 0 0 60px 0;
    width: 550px;
    margin: 0 auto;
  }
  @include mobile {
    width: 100%;
    padding-bottom: 40px;
  }
  .form__group {
    .form__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      color: #344054;
      display: block;
      padding-left: 3px;
      .required {
        color: var(--main)
      }
    }
    .form__input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid #d0d5dd;
      width: 100%;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      margin-top: 6px;
      font-size: 16px;
      &:focus {
        outline: 1px solid var(--main);
      }
    }
    .form__checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
      &+label::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #adb5bd;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }
      &:not(:disabled):not(:checked)+label:hover::before {
        border-color: #b3d7ff;
      }
      &:not(:disabled):active+label::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
      }
      &:focus+label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      &:focus:not(:checked)+label::before {
        border-color: #80bdff;
      }
      &:disabled+label::before {
        background-color: #e9ecef;
      }
      /* стили для чекбокса, находящегося в состоянии checked */
      &:checked+label::before {
        border-color: #0b76ef;
        background-color: #0b76ef;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }
      &-label {
        font-size: 16px;
        line-height: 150%;
        color: #475467;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        user-select: none;
        margin-left: 5px;
        @include mobile {
          display: block;
        }
        .form__link {
          text-decoration: underline;
          text-decoration-skip-ink: none;
          color: #475467;
          font-weight: 400;
          margin-left: 4px;
          @include mobile {
            display: block;
            margin-left: 24px;
          }
        }
      }
    }
  }
  .form__button {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
  .just-validate-error-label {
    font-size: 14px;
    line-height: 143%;
    padding-left: 3px;
    @include small-tablet {
      font-size: 12px;
    }
  }
}
  &__success {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    .success__img {
      height: 200px;
    }
    .success__title {
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      margin-top: 24px;
      color: var(--main);
      max-width: 300px;
    }
  }
}
.faq {
  padding: 96px 0;
  background-color: var(--bggrey);
  @include tablet {
    padding: 60px 0;
  }
  @include mobile {
    padding: 40px 0;
    margin-top: 0;
  }
  &__title {
    text-align: center;
  }
  &__list {
    max-width: 768px;
    margin: 64px auto 0 auto;
    @include tablet {
      margin-top: 40px;
    }
    @include mobile {
      margin-top: 24px;
    }
    .faq__item {
      padding: 24px 0 12px 0;
      &:not(:first-child) {
        border-top: 1px solid #eaecf0;
      }
      &.active {
        .item__question {
          &:after, &:hover:after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
          }
        }
      }
      .item__question {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 156%;
        color: #101828;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        &:hover {
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2376AEFE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
            @include small-tablet {
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
            }
          }
        }
        &:after {
          content: '';
          width: 24px;
          height: 24px;
          flex: 0 0 24px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
          @include mobile {
            margin-left: 20px;
          }
        }
      }
      .item__answer {
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--greylight);
        padding-right: 48px;
        max-height: 0;
        overflow: hidden;
        transition: .3s;
        @include small-tablet {
          font-size: 14px;
        }
      }
    }
  }
}
