// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Roboto";
  --content-width: 1180px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  // colors
  --text: #0F1010;
  --grey: #404040;
  --greylight: #666D6D;
  --greybtn: #E0E2E2;
  --main: #0260E8;
  --lightmain: #EDF4FF;
  --bg: #fff;
  --underline: #f2f3f3;
  --bggrey: #f9fafb;
}
