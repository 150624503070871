/* stylelint-disable */

@mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: (1920px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (1440px)) {
    @content;
  }
}

@mixin medium-tablet {
  @media (max-width: (1279px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (1023px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (767px)) {
    @content;
  }
}
