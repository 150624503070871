@charset "UTF-8";
:root {
  --font-family: "Roboto";
  --content-width: 1180px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --text: #0F1010;
  --grey: #404040;
  --greylight: #666D6D;
  --greybtn: #E0E2E2;
  --main: #0260E8;
  --lightmain: #EDF4FF;
  --bg: #fff;
  --underline: #f2f3f3;
  --bggrey: #f9fafb;
}

/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: thin;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: medium;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: bold;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: black;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.footer {
  margin-top: auto;
}

.main {
  margin-top: 85px;
}
@media (max-width: 767px) {
  .main {
    margin-top: 68px;
  }
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.orange {
  color: var(--orange-color);
}

.flex {
  display: flex;
}

.flex-a-center {
  display: flex;
  align-items: center;
}

.flex-a-end {
  display: flex;
  align-items: flex-end;
}

.flex-j-center {
  display: flex;
  justify-content: center;
}

.flex-j-sb {
  display: flex;
  justify-content: space-between;
}

.flex-j-sa {
  display: flex;
  justify-content: space-around;
}

.grid {
  display: grid;
}

.header {
  width: 100%;
  height: 85px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--underline);
  background-color: #fff;
  z-index: 100;
}
@media (max-width: 767px) {
  .header {
    height: 68px;
  }
}
.header.active {
  border: none;
}
.header__wrapper {
  max-width: 1440px;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
}
@media (max-width: 1440px) {
  .header__wrapper {
    padding: 0 15px;
  }
}
.header__logo {
  display: flex;
  align-items: center;
}
.header__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 1023px) {
  .header__menu {
    transform: scaleY(0);
    transform-origin: top;
    position: fixed;
    width: 100%;
    left: 0;
    top: 85px;
    background-color: #fff;
    padding: 24px 15px;
    border-bottom: 1px solid var(--underline);
    transition: 0.3s;
  }
}
@media (max-width: 767px) {
  .header__menu {
    height: calc(100% - 68px);
    top: 68px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.header__menu.active {
  transform: scaleY(1);
}
.header__menu .menu__list {
  display: flex;
  gap: 22px;
}
@media (max-width: 1023px) {
  .header__menu .menu__list {
    flex-direction: column;
    gap: 24px;
  }
}
.header__menu .menu__list .list__item.item-sub svg {
  margin: 0 0 3px 4px;
  transition: 0.3s;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item.item-sub svg {
    display: none;
  }
}
.header__menu .menu__list .list__item.item-sub.active .item__submenu {
  visibility: visible;
  transform: scaleY(1);
}
.header__menu .menu__list .list__item.item-sub.active svg {
  transform: rotate(180deg);
}
.header__menu .menu__list .list__item .item__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: var(--text);
  padding: 0 3px;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__link {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
  }
}
.header__menu .menu__list .list__item .item__link:hover {
  color: var(--main);
}
.header__menu .menu__list .list__item .item__link.active {
  font-weight: 600;
}
.header__menu .menu__list .list__item .item__submenu {
  transform: scaleY(0);
  transform-origin: top;
  visibility: hidden;
  position: absolute;
  border: 1px solid var(--underline);
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 24px;
  box-shadow: 2px 5px 13px 0 rgba(12, 32, 60, 0.06);
  margin-top: 15px;
  transition: 0.3s;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__submenu {
    visibility: visible;
    transform: scaleY(1);
    position: relative;
    border: none;
    margin-top: 0;
    padding-bottom: 0;
    box-shadow: none;
  }
}
.header__menu .menu__list .list__item .item__submenu .submenu__list {
  display: grid;
  gap: 12px;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__submenu .submenu__list {
    gap: 16px;
  }
}
.header__menu .menu__list .list__item .item__submenu .submenu__list .list__item .item__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__submenu .submenu__list .list__item .item__link {
    font-size: 16px;
  }
}
.header__menu .menu__wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
@media (max-width: 767px) {
  .header__menu .menu__wrapper {
    margin-top: auto;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .header__menu .menu__wrapper .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.header__burger {
  display: none;
}
@media (max-width: 1023px) {
  .header__burger {
    width: 30px;
    height: 27px;
    cursor: pointer;
    background: transparent;
    border: none;
    position: relative;
    padding: 0;
    display: flex;
    align-items: flex-start;
    margin-left: auto;
  }
  .header__burger .burger__line, .header__burger .burger__line:after, .header__burger .burger__line:before {
    width: 30px;
    height: 3px;
  }
  .header__burger .burger__line {
    position: relative;
    transform: translateY(10px);
    background: var(--greylight);
    transition: all 0ms 300ms;
    display: block;
    border-radius: 3px;
  }
  .header__burger .burger__line.animate {
    background: transparent;
  }
  .header__burger .burger__line:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    border-radius: 3px;
    background: var(--greylight);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .header__burger .burger__line:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 3px;
    background: var(--greylight);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .header__burger .burger__line.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .header__burger .burger__line.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.footer {
  padding: 60px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-top: 100px;
}
@media (max-width: 1440px) {
  .footer {
    margin-top: 40px;
  }
}
@media (max-width: 1023px) {
  .footer {
    margin-top: 0;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .footer {
    margin-top: 0;
  }
}
.footer__wrapper {
  display: grid;
  grid-template-columns: 1fr 200px 280px 1fr;
  gap: 30px;
}
@media (max-width: 1023px) {
  .footer__wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .footer__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }
}
@media (max-width: 1023px) {
  .footer__column.column-big {
    display: contents;
  }
}
@media (max-width: 767px) {
  .footer__column.column-big .footer__logo {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .footer__column.column-big .footer__logo .logo__img {
    width: 107px;
  }
}
.footer__column.column-big .footer__privacy {
  margin-top: 40px;
  font-size: 12px;
  line-height: 170%;
  display: grid;
  gap: 4px;
}
@media (max-width: 1023px) {
  .footer__column.column-big .footer__privacy {
    order: 3;
    grid-area: 3/1/4/3;
    display: flex;
    gap: 24px;
  }
}
@media (max-width: 767px) {
  .footer__column.column-big .footer__privacy {
    flex: 0 0 100%;
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 7px;
  }
}
.footer__column.column-big .footer__privacy .privacy__link {
  display: block;
  color: var(--greylight);
  font-weight: 400;
}
@media (max-width: 767px) {
  .footer__column.column-big .footer__privacy .privacy__link {
    text-decoration: underline;
    flex: 0 0 100%;
    text-align: center;
  }
}
.footer__column.column-big .footer__privacy .privacy__link:hover {
  color: var(--grey);
}
@media (max-width: 1023px) {
  .footer__column.column-contact {
    order: 1;
  }
}
@media (max-width: 767px) {
  .footer__column.column-contact {
    flex: 0 0 35%;
  }
}
.footer__column.column-contact .contact__list {
  display: grid;
  gap: 6px;
}
@media (max-width: 1023px) {
  .footer__column.column-social {
    order: 2;
  }
}
@media (max-width: 767px) {
  .footer__column.column-social {
    flex: 0 0 100%;
  }
}
.footer__column.column-social .social__list {
  display: flex;
  gap: 14px;
}
@media (max-width: 767px) {
  .footer__column.column-social .social__list {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .footer__column.column-social .social__title {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer__column.column-address {
    flex: 0 0 50%;
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(140, 157, 173, 0.7490196078);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
}
.popup__wrapper {
  position: relative;
  flex: 0 0 580px;
}
@media (max-width: 767px) {
  .popup__wrapper {
    flex: 0 0 95%;
    margin: 0 15px;
  }
}
.popup__close {
  position: absolute;
  right: 20px;
  top: 17px;
  padding: 4px;
  z-index: 2;
  stroke: var(--greylight);
}
@media (max-width: 1023px) {
  .popup__close {
    top: 10px;
    right: 22px;
  }
}
@media (max-width: 767px) {
  .popup__close {
    right: 10px;
  }
}
.popup__close:hover svg {
  stroke: var(--main);
}
.popup__form.form__container {
  background-color: #ffffff;
  position: relative;
}
@media (max-width: 1440px) {
  .popup__form.form__container {
    gap: 16px;
  }
}
@media (max-width: 1023px) {
  .popup__form.form__container {
    padding: 40px 20px;
  }
}
@media (max-width: 1440px) {
  .popup__form.form__container .form__group .form__checkbox-label {
    font-size: 14px;
  }
}
@media (max-width: 1440px) {
  .popup__form.form__container .form__group .form__input {
    margin-top: 2px;
  }
}
.popup__form .form__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: var(--text);
  margin: 0;
  max-width: 500px;
}
@media (max-width: 1440px) {
  .popup__form .form__title {
    font-size: 20px;
  }
}
.popup__form .form__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: var(--text);
  margin-top: -10px;
}
@media (max-width: 1440px) {
  .popup__form .form__text {
    font-size: 16px;
  }
}

.canihave_widget {
  z-index: 0;
}

@media (max-width: 767px) {
  .hero {
    margin-top: 10px;
  }
}
.hero__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 21px;
}
@media (max-width: 1023px) {
  .hero__wrapper {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .hero__wrapper {
    display: grid;
    gap: 3px;
  }
}
.hero__info {
  flex: 0 0 480px;
  height: min-content;
  margin-top: 76px;
}
@media (max-width: 1920px) {
  .hero__info {
    flex: 0 0 410px;
  }
}
@media (max-width: 1023px) {
  .hero__info {
    margin-top: 0;
  }
}
.hero__logo {
  font-weight: 900;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.hero__logo span {
  color: var(--main);
}
.hero__title {
  margin-top: 12px;
}
.hero__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 12px;
}
@media (max-width: 767px) {
  .hero__text {
    font-size: 16px;
  }
}
.hero__btns {
  display: flex;
  gap: 10px;
}
.hero__btn {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .hero__btn {
    margin-top: 24px;
  }
}

.solution__wrapper {
  border: 2px dashed #b0d1ff;
  border-radius: 16px;
  padding: 60px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 767px) {
  .solution__wrapper {
    grid-template-columns: 1fr;
    padding: 24px;
    gap: 24px;
  }
}
.solution__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: var(--grey);
  margin: 0;
}
@media (max-width: 1023px) {
  .solution__title {
    font-size: 20px;
  }
}
.solution__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: var(--grey);
}
@media (max-width: 1023px) {
  .solution__text {
    font-size: 16px;
  }
}

.effect__title {
  width: 290px;
}
@media (max-width: 767px) {
  .effect__title {
    width: 190px;
  }
}
.effect__title span {
  color: var(--main);
}
.effect__container {
  width: 192px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg width='168' height='40' viewBox='0 0 168 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9987 8.33337V31.6667M19.9987 31.6667L31.6654 20M19.9987 31.6667L8.33203 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M83.9987 8.33337V31.6667M83.9987 31.6667L95.6654 20M83.9987 31.6667L72.332 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M147.999 8.33337V31.6667M147.999 31.6667L159.665 20M147.999 31.6667L136.332 20' stroke='%23E0E2E2' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 767px) {
  .effect__container {
    display: none;
  }
}
.effect__wrapper.wrapper-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.effect__wrapper.wrapper-list {
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .effect__wrapper.wrapper-list {
    margin-top: 20px;
  }
}
.effect__wrapper.wrapper-list .effect__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media (max-width: 1279px) {
  .effect__wrapper.wrapper-list .effect__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .effect__wrapper.wrapper-list .effect__list {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}
.effect__wrapper.wrapper-list .effect__list .list__item {
  border-radius: 8px;
  padding: 24px;
  background-color: var(--bggrey);
}
@media (max-width: 767px) {
  .effect__wrapper.wrapper-list .effect__list .list__item .item__icon {
    display: none;
  }
}
.effect__wrapper.wrapper-list .effect__list .list__item .item__subtitle {
  font-weight: 600;
  font-size: 20px;
  color: var(--text);
  line-height: 130%;
  margin: 40px 0 0 0;
}
@media (max-width: 1279px) {
  .effect__wrapper.wrapper-list .effect__list .list__item .item__subtitle {
    font-size: 18px;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .effect__wrapper.wrapper-list .effect__list .list__item .item__subtitle {
    font-size: 16px;
    margin-top: 0;
  }
}
.effect__wrapper.wrapper-list .effect__list .list__item .item__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: block;
  color: var(--greylight);
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .effect__wrapper.wrapper-list .effect__list .list__item .item__text {
    font-size: 14px;
  }
}
.effect__wrapper.wrapper-list .effect__list .list__item .item__text i {
  display: block;
  margin-top: 8px;
}

.video__title {
  text-align: center;
}
@media (max-width: 767px) {
  .video__title {
    text-align: left;
    width: 240px;
  }
}
.video__container {
  background-color: var(--bggrey);
  border-radius: 8px;
  padding: 60px;
}
@media (max-width: 1023px) {
  .video__container {
    padding: 40px 20px;
  }
}
@media (max-width: 767px) {
  .video__container {
    padding: 16px;
  }
}
.video__container iframe {
  border-radius: 10px;
  overflow: hidden;
  height: 500px;
  width: 889px;
  margin: 40px auto 0 auto;
  display: block;
}
@media (max-width: 1023px) {
  .video__container iframe {
    width: 100%;
    height: 380px;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .video__container iframe {
    height: 200px;
  }
}

.how__wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 380px);
  position: relative;
}
@media (max-width: 1279px) {
  .how__wrapper {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .how__wrapper {
    gap: 8px;
    justify-items: center;
  }
}
.how__column.column-start {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 1279px) {
  .how__column.column-start {
    flex-direction: row;
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .how__column.column-start {
    flex-direction: column;
  }
}
.how__column.column-query {
  padding-top: 185px;
}
@media (max-width: 1279px) {
  .how__column.column-query {
    padding-top: 0;
    width: 778px;
  }
}
@media (max-width: 1023px) {
  .how__column.column-query {
    width: 698px;
  }
}
@media (max-width: 767px) {
  .how__column.column-query {
    width: 100%;
  }
}
.how__column.column-end {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 1279px) {
  .how__column.column-end {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .how__column.column-end {
    flex-direction: column;
    width: 100%;
  }
}
.how__item {
  border-radius: 8px;
  padding: 24px;
  background-color: var(--bggrey);
}
@media (max-width: 1279px) {
  .how__item {
    flex: 0 0 380px;
  }
}
@media (max-width: 1023px) {
  .how__item {
    flex: 0 0 340px;
  }
}
@media (max-width: 767px) {
  .how__item {
    flex: 0 0 auto;
  }
}
.how__item.item-pt {
  padding-top: 0;
}
.how__item .item__subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #101828;
  margin: 24px 0 0 0;
}
@media (max-width: 1023px) {
  .how__item .item__subtitle {
    font-size: 16px;
  }
}
.how__item .item__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #475467;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .how__item .item__text {
    font-size: 14px;
  }
}
.how__arrow {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-color: var(--bggrey);
  border: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
@media (max-width: 1279px) {
  .how__arrow {
    transform: rotate(90deg);
  }
}
@media (max-width: 767px) {
  .how__arrow {
    position: relative;
  }
}
.how__arrow.arrow-first {
  top: 299px;
  left: 363px;
}
@media (max-width: 1279px) {
  .how__arrow.arrow-first {
    top: 460px;
    left: 364px;
  }
}
@media (max-width: 1023px) {
  .how__arrow.arrow-first {
    left: 323px;
    top: 418px;
  }
}
@media (max-width: 767px) {
  .how__arrow.arrow-first {
    left: 0;
    top: 0;
  }
}
.how__arrow.arrow-second {
  left: 768px;
  top: 509px;
}
@media (max-width: 1279px) {
  .how__arrow.arrow-second {
    top: 930px;
    left: 364px;
  }
}
@media (max-width: 1023px) {
  .how__arrow.arrow-second {
    left: 323px;
    top: 874px;
  }
}
@media (max-width: 767px) {
  .how__arrow.arrow-second {
    left: 0;
    top: 0;
  }
}

.category__title {
  text-align: center;
}
.category__btn {
  margin: 24px auto 0 auto;
}
.category__wrapper {
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .category__wrapper {
    margin-top: 24px;
  }
}
.category__wrapper .sliders__item.item-right {
  margin-top: 18px;
}
@media (max-width: 767px) {
  .category__wrapper .sliders__item.item-right {
    margin-top: 16px;
  }
}
.category__wrapper .slider__item {
  width: auto;
  display: flex;
}
.category__wrapper .slider__item .item__wrapper {
  flex: 0 0 100%;
  width: 220px;
  background-color: #F2F4F6;
  height: 220px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}
@media (max-width: 1440px) {
  .category__wrapper .slider__item .item__wrapper {
    height: 200px;
    width: 200px;
  }
}
@media (max-width: 1279px) {
  .category__wrapper .slider__item .item__wrapper {
    height: 180px;
    width: 180px;
  }
}
@media (max-width: 1023px) {
  .category__wrapper .slider__item .item__wrapper {
    height: 140px;
    width: 140px;
  }
}
.category__wrapper .slider__item .item__wrapper .item__img {
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .category__wrapper .slider__item .item__wrapper .item__img {
    max-width: 80%;
  }
}
.category .swiper-wrapper {
  width: 100%;
}

.services__title {
  text-align: center;
}
.services__info {
  margin-top: 16px;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: var(--text);
}
@media (max-width: 1023px) {
  .services__info {
    font-size: 16px;
    margin-top: 12px;
  }
}
.services__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 56px;
}
@media (max-width: 1023px) {
  .services__list {
    gap: 8px;
    margin-top: 18px;
  }
}
@media (max-width: 767px) {
  .services__list {
    grid-template-columns: 1fr;
  }
}
.services__list .list__column {
  display: grid;
  gap: 20px;
}
@media (max-width: 1023px) {
  .services__list .list__column {
    gap: 8px;
  }
}
.services__item {
  border-radius: 16px;
  padding: 40px;
  background-color: #edf4ff;
}
.services__item.item-pt {
  padding-top: 0;
}
.services__item.item-pt .item__img {
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .services__item.item-pt .item__img {
    margin-bottom: 16px;
  }
}
.services__item.item-pb {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
.services__item.item-pb .item__picture {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .services__item.item-pb .item__picture {
    margin-top: 16px;
  }
}
.services__item .item__use {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--main);
}
.services__item .item__subtitle {
  margin-bottom: 8px;
}
.services__item .item__wrapper {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
@media (max-width: 1023px) {
  .services__item .item__wrapper {
    margin-top: 16px;
  }
}
.services__item .item__text {
  color: #666d6d;
}
.tasks__title {
  text-align: center;
}
@media (max-width: 767px) {
  .tasks__title {
    text-align: left;
  }
}
.tasks__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .tasks__wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    gap: 16px;
  }
}
.tasks__item {
  padding: 24px;
  border: 1px solid #edf4ff;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .tasks__item {
    padding-top: 73px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%230260E8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: 24px 24px;
  }
}
.tasks__item:nth-child(1) .item__subtitle {
  max-width: 200px;
}
@media (max-width: 767px) {
  .tasks__item .item__img {
    display: none;
  }
}
.tasks__item .item__subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 125%;
  color: #101828;
  margin: 0;
}
@media (max-width: 1023px) {
  .tasks__item .item__subtitle {
    font-size: 16px;
  }
}
.tasks__btn {
  margin: 24px auto 0 auto;
}

.advantages__title {
  text-align: center;
}
@media (max-width: 767px) {
  .advantages__title {
    text-align: left;
  }
}
.advantages__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 32px;
}
@media (max-width: 1023px) {
  .advantages__wrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .advantages__wrapper {
    gap: 10px;
  }
}
.advantages__column.column-opportunity {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media (max-width: 767px) {
  .advantages__column.column-opportunity {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.advantages__column.column-opportunity .opportunity__item {
  border-radius: 8px;
  padding: 24px;
  background-color: var(--bggrey);
}
@media (max-width: 767px) {
  .advantages__column.column-opportunity .opportunity__item {
    display: grid;
    grid-template-columns: 47px 1fr;
    column-gap: 10px;
    padding: 24px 10px;
  }
}
.advantages__column.column-opportunity .opportunity__item .item__text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #475467;
}
@media (max-width: 767px) {
  .advantages__column.column-opportunity .opportunity__item .item__text {
    display: flex;
    align-items: flex-start;
    margin-top: 0;
    grid-area: 2/2/3/3;
  }
}
@media (max-width: 767px) {
  .advantages__column.column-opportunity .opportunity__item .item__icon {
    grid-area: 1/1/3/2;
  }
}
@media (max-width: 767px) {
  .advantages__column.column-opportunity .opportunity__item .item__subtitle {
    margin-top: 0;
    display: flex;
    align-items: flex-end;
  }
}
.advantages__column.column-analytics {
  padding: 40px 40px 0 40px;
  border-radius: 8px;
  background-color: var(--lightmain);
}
@media (max-width: 767px) {
  .advantages__column.column-analytics {
    padding: 20px 10px 0 10px;
  }
}
.advantages__column.column-analytics .analytics__item {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.advantages__column.column-analytics .item__subtitle {
  margin-top: 0;
}
.advantages__column.column-analytics .item__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #0f1010;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .advantages__column.column-analytics .item__text {
    font-size: 14px;
  }
}
.advantages__column.column-analytics .item__wrapper {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 40px;
}
.advantages__column.column-analytics .item__picture {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
}
.advantages__column .item__subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 125%;
  color: #101828;
  margin: 40px 0 0 0;
}
@media (max-width: 1023px) {
  .advantages__column .item__subtitle {
    font-size: 16px;
  }
}

.calc {
  padding: 80px 0;
  background-color: var(--lightmain);
}
@media (max-width: 1023px) {
  .calc {
    padding: 40px 0;
  }
}
.calc__wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 1023px) {
  .calc__wrapper {
    grid-template-columns: 330px 1fr;
  }
}
@media (max-width: 767px) {
  .calc__wrapper {
    grid-template-columns: 1fr;
  }
}
.calc__title {
  color: #0f1010;
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  margin: 0;
}
@media (max-width: 1023px) {
  .calc__title {
    font-size: 24px;
  }
}
.calc__title .title__blue {
  color: var(--main);
  display: block;
}
.calc__container {
  position: relative;
}
.calc__step.step-info .form {
  display: grid;
  gap: 16px;
}
@media (max-width: 1023px) {
  .calc__step.step-info .form {
    padding-top: 0;
  }
}
.calc__step.step-info .form__row {
  width: 100%;
  outline: 1px solid var(--underline);
  border-radius: 8px;
  position: relative;
  display: flex;
}
.calc__step.step-info .form__row.focus {
  outline: 1px solid var(--main);
}
.calc__step.step-info .form__row.focus .form__label {
  font-size: 12px;
  color: var(--main);
  top: 25%;
}
.calc__step.step-info .form__input {
  width: 100%;
  height: 100%;
  padding: 20px 14px 8px 14px;
  font-size: 16px;
}
.calc__step.step-info .form__input:focus {
  outline: none;
}
.calc__step.step-info .form__label {
  font-weight: 400;
  font-size: 16px;
  color: #666d6d;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
@media (max-width: 767px) {
  .calc__step.step-info .form__approve {
    margin-top: 10px;
  }
}
.calc__step.step-final {
  border-radius: 12px;
  padding: 24px;
  border: 1px dashed #b0d1ff;
  position: absolute;
  background-color: var(--lightmain);
  left: -5px;
  top: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.calc__step.step-final.visible {
  opacity: 1;
  visibility: visible;
}
.calc__step.step-final .step__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin: 0;
  color: var(--text);
}
@media (max-width: 1023px) {
  .calc__step.step-final .step__subtitle {
    font-size: 20px;
  }
}
.calc__step.step-final .step__result {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: var(--main);
  margin-top: 4px;
}
.calc__step.step-final .step__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #000;
  margin-top: 24px;
}
@media (max-width: 1023px) {
  .calc__step.step-final .step__text {
    font-size: 16px;
  }
}
.calc__step.step-final .step__btn {
  margin-top: 24px;
}
.calc__step.step-load {
  position: absolute;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background-color: var(--lightmain);
  left: -5px;
  top: -5px;
  z-index: -1;
}
.calc__step.step-load.visible {
  z-index: 3;
}

.price {
  background-color: var(--bggrey);
  margin-top: 0;
  padding: 66px 0 80px 0;
}
@media (max-width: 1023px) {
  .price {
    padding-top: 40px;
  }
}
.price .tariff__title {
  text-align: center;
}
.price .tariff__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 46px repeat(4, 1fr);
  gap: 16px;
  margin-top: 40px;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #0f1010;
}
@media (max-width: 1023px) {
  .price .tariff__wrapper {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .price .tariff__wrapper {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: 34px repeat(4, 1fr);
    margin-top: 28px;
  }
}
.price .tariff__row {
  display: contents;
}
@media (max-width: 767px) {
  .price .tariff__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    grid-area: 1/1/2/3;
  }
}
.price .tariff__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
  background-color: #fff;
  text-align: center;
}
@media (max-width: 1023px) {
  .price .tariff__item {
    padding: 12px 10px;
  }
}
@media (max-width: 767px) {
  .price .tariff__item {
    display: none;
    padding: 6px 10px;
  }
}
@media (max-width: 767px) {
  .price .tariff__item.active {
    display: flex;
  }
}
.price .tariff__item.item-full {
  grid-area: 3/2/4/5;
}
@media (max-width: 767px) {
  .price .tariff__item.item-full {
    display: flex;
    grid-area: 3/2/4/3;
  }
}
.price .tariff__item.item-blue {
  border: 2px solid #b0d1ff;
}
@media (max-width: 767px) {
  .price .tariff__item.item-blue {
    border: none;
  }
}
.price .tariff__item.item-title {
  padding: 10px;
  font-weight: 500;
  background-color: #dbe9ff;
}
@media (max-width: 767px) {
  .price .tariff__item.item-title {
    display: flex;
  }
}
.price .tariff__item.item-title:nth-child(3) {
  grid-area: 2/1/3/2;
}
.price .tariff__item.item-title:nth-child(7) {
  grid-area: 3/1/4/2;
}
.price .tariff__item.item-title:nth-child(9) {
  grid-area: 4/1/5/2;
}
.price .tariff__item.item-title:nth-child(13) {
  grid-area: 5/1/6/2;
}
.price .tariff__item.item-toggle {
  font-weight: 500;
}
@media (max-width: 767px) {
  .price .tariff__item.item-toggle {
    display: flex;
    border-radius: 30px;
    border: 1px solid #dbe9ff;
    color: #666d6d;
    flex: 0 0 60px;
  }
}
@media (max-width: 767px) {
  .price .tariff__item.item-toggle.choose {
    background-color: #edf4ff;
    border: 1px solid #edf4ff;
    color: var(--main);
  }
}
.price .tariff__item.item-hidden {
  background-color: #fff;
}
@media (max-width: 767px) {
  .price .tariff__item.item-hidden {
    display: none;
  }
}
.price .rate {
  margin-top: 64px;
}
@media (max-width: 767px) {
  .price .rate {
    margin-top: 40px;
  }
}
.price .rate__title {
  text-align: center;
}
.price .rate__wrapper {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}
@media (max-width: 1279px) {
  .price .rate__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .price .rate__wrapper {
    margin-top: 24px;
    grid-template-columns: 1fr;
  }
}
.price .rate__item {
  border-radius: 16px;
  padding: 32px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  background-color: var(--lightmain);
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
  display: flex;
  flex-direction: column;
}
.price .rate__item:nth-child(4) .item__more {
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 767px) {
  .price .rate__item:nth-child(4) .item__more {
    display: none;
  }
}
.price .rate__item.active {
  transition: 0.3s;
}
@media (max-width: 1279px) {
  .price .rate__item.active .item__list .list__item:nth-child(n+4) {
    display: flex;
  }
}
.price .rate__item .item__list {
  display: grid;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 40px;
}
@media (max-width: 1279px) {
  .price .rate__item .item__list {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .price .rate__item .item__list {
    margin-bottom: 0;
    margin-top: 24px;
  }
}
.price .rate__item .item__list .list__item {
  color: #475467;
  position: relative;
  display: flex;
  gap: 5px;
}
@media (max-width: 1279px) {
  .price .rate__item .item__list .list__item {
    width: 100%;
  }
}
@media (max-width: 1279px) {
  .price .rate__item .item__list .list__item:nth-child(n+4) {
    display: none;
  }
}
.price .rate__item .item__list .list__item svg {
  fill: var(--main);
  flex: 0 0 20px;
  height: 20px;
}
.price .rate__item .item__subtitle {
  margin: 0;
  text-align: center;
}
.price .rate__item .item__more {
  display: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-decoration: underline;
  color: var(--main);
  margin-top: auto;
}
@media (max-width: 1279px) {
  .price .rate__item .item__more {
    display: block;
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .price .rate__item .item__more {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
.price .rate__item .item__btn {
  margin-top: auto;
  border-radius: 68px;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
@media (max-width: 1279px) {
  .price .rate__item .item__btn {
    margin: 24px auto 0 auto;
    width: 313px;
  }
}
@media (max-width: 1023px) {
  .price .rate__item .item__btn {
    width: 248px;
  }
}
@media (max-width: 767px) {
  .price .rate__item .item__btn {
    margin-top: 24px;
  }
}

@media (max-width: 1023px) {
  .form {
    background-color: var(--lightmain);
    margin-top: 0;
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .form {
    padding-top: 40px;
  }
}
.form__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media (max-width: 1279px) {
  .form__wrapper {
    grid-template-columns: 446px 1fr;
  }
}
@media (max-width: 1023px) {
  .form__wrapper {
    grid-template-columns: 1fr;
  }
}
.form__info {
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .form__info {
    width: 550px;
    margin: 0 auto;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .form__info {
    width: 100%;
  }
}
.form__info .info__logo {
  font-weight: 900;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--grey);
}
.form__info .info__logo .blue {
  color: var(--main);
}
.form__info .info__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: var(--text);
  margin: 12px 0 0 0;
}
@media (max-width: 1023px) {
  .form__info .info__title {
    font-size: 24px;
    line-height: 120%;
  }
}
.form__info .info__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 14px;
}
@media (max-width: 1023px) {
  .form__info .info__text {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .form__info .info__text {
    display: none;
  }
}
.form__container {
  border-radius: 10px;
  padding: 52px 32px;
  background-color: var(--lightmain);
  display: grid;
  gap: 24px;
  position: relative;
}
@media (max-width: 1023px) {
  .form__container {
    padding: 0 0 60px 0;
    width: 550px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .form__container {
    width: 100%;
    padding-bottom: 40px;
  }
}
.form__container .form__group .form__label {
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: #344054;
  display: block;
  padding-left: 3px;
}
.form__container .form__group .form__label .required {
  color: var(--main);
}
.form__container .form__group .form__input {
  border-radius: 8px;
  padding: 10px 14px;
  border: 1px solid #d0d5dd;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin-top: 6px;
  font-size: 16px;
}
.form__container .form__group .form__input:focus {
  outline: 1px solid var(--main);
}
.form__container .form__group .form__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  /* стили для чекбокса, находящегося в состоянии checked */
}
.form__container .form__group .form__checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.form__container .form__group .form__checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #b3d7ff;
}
.form__container .form__group .form__checkbox:not(:disabled):active + label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.form__container .form__group .form__checkbox:focus + label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form__container .form__group .form__checkbox:focus:not(:checked) + label::before {
  border-color: #80bdff;
}
.form__container .form__group .form__checkbox:disabled + label::before {
  background-color: #e9ecef;
}
.form__container .form__group .form__checkbox:checked + label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.form__container .form__group .form__checkbox-label {
  font-size: 16px;
  line-height: 150%;
  color: #475467;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .form__container .form__group .form__checkbox-label {
    display: block;
  }
}
.form__container .form__group .form__checkbox-label .form__link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #475467;
  font-weight: 400;
  margin-left: 4px;
}
@media (max-width: 767px) {
  .form__container .form__group .form__checkbox-label .form__link {
    display: block;
    margin-left: 24px;
  }
}
.form__container .form__button {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.form__container .just-validate-error-label {
  font-size: 14px;
  line-height: 143%;
  padding-left: 3px;
}
@media (max-width: 1023px) {
  .form__container .just-validate-error-label {
    font-size: 12px;
  }
}
.form__success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.form__success.active {
  opacity: 1;
  visibility: visible;
}
.form__success .success__img {
  height: 200px;
}
.form__success .success__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  margin-top: 24px;
  color: var(--main);
  max-width: 300px;
}

.faq {
  padding: 96px 0;
  background-color: var(--bggrey);
}
@media (max-width: 1440px) {
  .faq {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  .faq {
    padding: 40px 0;
    margin-top: 0;
  }
}
.faq__title {
  text-align: center;
}
.faq__list {
  max-width: 768px;
  margin: 64px auto 0 auto;
}
@media (max-width: 1440px) {
  .faq__list {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .faq__list {
    margin-top: 24px;
  }
}
.faq__list .faq__item {
  padding: 24px 0 12px 0;
}
.faq__list .faq__item:not(:first-child) {
  border-top: 1px solid #eaecf0;
}
.faq__list .faq__item.active .item__question:after, .faq__list .faq__item.active .item__question:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}
.faq__list .faq__item .item__question {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 156%;
  color: #101828;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.faq__list .faq__item .item__question:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2376AEFE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}
@media (max-width: 1023px) {
  .faq__list .faq__item .item__question:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
  }
}
.faq__list .faq__item .item__question:after {
  content: "";
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='%2398A2B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
}
@media (max-width: 767px) {
  .faq__list .faq__item .item__question:after {
    margin-left: 20px;
  }
}
.faq__list .faq__item .item__answer {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--greylight);
  padding-right: 48px;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}
@media (max-width: 1023px) {
  .faq__list .faq__item .item__answer {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .hero__wrapper {
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .hero__img {
    margin-left: -15px;
  }
}

.recommends .slider__wrapper {
  margin-top: 42px;
  width: 100%;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 1023px) {
  .recommends .slider__wrapper {
    gap: 0;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .recommends .slider__wrapper {
    margin-top: 16px;
  }
}
.recommends .slider__wrapper .slider__item {
  flex: 0 0 280px;
  padding: 24px 24px 45px 24px;
  border-radius: 8px;
  border: 1px solid var(--lightmain);
  box-sizing: border-box;
  display: grid;
  justify-content: space-between;
  grid-template-rows: 1fr 134px;
}
@media (max-width: 1279px) {
  .recommends .slider__wrapper .slider__item {
    padding: 16px 16px 20px 16px;
  }
}
@media (max-width: 1023px) {
  .recommends .slider__wrapper .slider__item {
    padding-bottom: 16px;
  }
}
.recommends .slider__wrapper .slider__item:last-child {
  padding: 0 24px 45px 24px;
}
@media (max-width: 1279px) {
  .recommends .slider__wrapper .slider__item:last-child {
    padding: 0 16px 20px 16px;
  }
}
@media (max-width: 1023px) {
  .recommends .slider__wrapper .slider__item:last-child {
    padding-bottom: 16px;
  }
}
.recommends .slider__wrapper .slider__item .item__info {
  height: 134px;
}
.recommends .slider__wrapper .slider__item .item__subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 139%;
  margin: 24px 0 0 0;
}
@media (max-width: 1279px) {
  .recommends .slider__wrapper .slider__item .item__subtitle {
    font-size: 16px;
  }
}
.recommends .slider__wrapper .slider__item .item__text {
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}
@media (max-width: 1279px) {
  .recommends .slider__wrapper .slider__item .item__text {
    font-size: 14px;
  }
}

.try {
  background-color: var(--lightmain);
  padding: 80px 0 80px;
  text-align: center;
}
@media (max-width: 1023px) {
  .try {
    padding: 60px 0 60px;
  }
}
@media (max-width: 767px) {
  .try {
    text-align: left;
    padding: 40px 0 40px 0;
  }
}
.try__info {
  width: 785px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .try__info {
    width: 100%;
    padding: 0 var(--container-offset);
  }
}
.try__info .info__logo {
  font-weight: 900;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--grey);
}
@media (max-width: 767px) {
  .try__info .info__logo {
    text-align: center;
  }
}
.try__info .info__logo .blue {
  color: var(--main);
}
.try__info .info__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: var(--text);
  margin: 12px 0 0 0;
}
@media (max-width: 1023px) {
  .try__info .info__title {
    font-size: 24px;
    line-height: 120%;
    margin: 12px auto 0 auto;
    max-width: 515px;
  }
}
@media (max-width: 767px) {
  .try__info .info__title {
    font-size: 20px;
    text-align: center;
  }
}
.try__info .info__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 14px;
}
@media (max-width: 1023px) {
  .try__info .info__text {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .try__info .info__text {
    display: none;
  }
}
.try__info .info__container {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 32px;
}
@media (max-width: 767px) {
  .try__info .info__container {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .effect.effect-widget .effect__title {
    width: 280px;
  }
}
.effect.effect-widget .effect__wrapper.wrapper-list .effect__list {
  gap: 12px;
}
@media (max-width: 767px) {
  .effect.effect-widget .effect__wrapper.wrapper-list .effect__list .list__item .item__icon {
    display: block;
  }
}
@media (max-width: 767px) {
  .effect.effect-widget .effect__wrapper.wrapper-list .effect__list .list__item .item__subtitle {
    margin-top: 16px;
  }
}

.solution.solution-widget .solution__title {
  font-style: italic;
  font-size: 20px;
}
@media (max-width: 1023px) {
  .solution.solution-widget .solution__title {
    font-size: 16px;
  }
}
.solution.solution-widget .solution__title .title__author {
  display: block;
  margin-top: 20px;
}
@media (max-width: 1023px) {
  .solution.solution-widget .solution__title .title__author {
    margin-top: 16px;
  }
}

.widget .slider__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 580px 580px;
  gap: 20px;
  margin-top: 42px;
}
@media (max-width: 1023px) {
  .widget .slider__wrapper {
    display: flex;
    gap: 0;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .widget .slider__wrapper {
    margin-top: 16px;
  }
}
.widget .slider__wrapper .slider__item {
  border-radius: 16px;
  padding: 40px;
  background-color: var(--lightmain);
  box-sizing: border-box;
  display: grid;
  align-content: space-between;
}
@media (max-width: 1023px) {
  .widget .slider__wrapper .slider__item {
    padding: 24px;
    min-height: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  .widget .slider__wrapper .slider__item {
    padding: 16px;
  }
}
.widget .slider__wrapper .slider__item .item__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin: 0 0 3px 0;
}
@media (max-width: 1279px) {
  .widget .slider__wrapper .slider__item .item__subtitle {
    font-size: 16px;
    margin-top: 16px;
    height: 42px;
  }
}
@media (max-width: 767px) {
  .widget .slider__wrapper .slider__item .item__subtitle {
    height: 83px;
  }
}

.tasks.tasks-widget .tasks__wrapper {
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .tasks.tasks-widget .tasks__wrapper {
    grid-template-columns: 1fr;
  }
}
.tasks.tasks-widget .tasks__wrapper .tasks__item:nth-child(1) .item__subtitle {
  max-width: 100%;
}
.tasks.tasks-widget .advantages__column.column-opportunity {
  grid-template-columns: 1fr;
}
.tasks.tasks-widget .advantages__column.column-opportunity .opportunity__item {
  display: block;
}
.tasks.tasks-widget .advantages__column.column-opportunity .opportunity__item .item__subtitle {
  margin-top: 21px;
}
@media (max-width: 1023px) {
  .tasks.tasks-widget .advantages__column.column-analytics .item__picture {
    margin-top: 9px;
  }
}

.connection {
  background-color: var(--bggrey);
  padding: 80px 0;
}
@media (max-width: 1023px) {
  .connection {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  .connection {
    padding: 40px 0 24px;
  }
}
.connection__title {
  text-align: center;
}
@media (max-width: 767px) {
  .connection__title {
    text-align: left;
  }
}
.connection__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 32px;
}
@media (max-width: 1279px) {
  .connection__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .connection__list {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 24px;
  }
}
.connection__list .list__item {
  border: 1px solid var(--greybtn);
  border-radius: 8px;
  padding: 24px;
}
.connection__list .list__item .item__subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: var(--main);
}
.connection__list .list__item .item__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 139%;
  text-align: center;
  color: #101828;
  margin-top: 4px;
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .connection__list .list__item .item__text {
    font-weight: 500;
    font-size: 16px;
  }
}
.connection__btn {
  margin: 32px auto 0;
}
@media (max-width: 1023px) {
  .connection__btn {
    margin-top: 24px;
  }
}

.chat {
  margin-top: 50px;
  padding-top: 100px;
}
@media (max-width: 1440px) {
  .chat {
    margin-top: 0;
  }
}
@media (max-width: 1023px) {
  .chat {
    padding-top: 60px;
  }
}
.chat__wrapper {
  position: relative;
  height: 675px;
  display: flex;
  align-items: center;
}
@media (max-width: 1279px) {
  .chat__wrapper {
    height: 580px;
  }
}
@media (max-width: 1023px) {
  .chat__wrapper {
    height: 460px;
  }
}
@media (max-width: 767px) {
  .chat__wrapper {
    height: auto;
    background-color: var(--lightmain);
    flex-direction: column;
    padding-bottom: 24px;
  }
}
.chat__info {
  background-color: var(--lightmain);
  padding: 70px 90px;
  width: 100%;
  border-radius: 16px;
}
@media (max-width: 1023px) {
  .chat__info {
    padding: 40px 35px;
  }
}
@media (max-width: 767px) {
  .chat__info {
    padding: 40px 0;
  }
}
.chat__info .info__title, .chat__info .info__text {
  max-width: 655px;
  font-size: 24px;
}
@media (max-width: 1279px) {
  .chat__info .info__title, .chat__info .info__text {
    font-size: 20px;
    max-width: 530px;
  }
}
@media (max-width: 1023px) {
  .chat__info .info__title, .chat__info .info__text {
    font-size: 18px;
    max-width: 445px;
  }
}
.chat__info .info__title {
  color: var(--text);
  margin: 0;
}
.chat__info .info__text {
  line-height: 130%;
  color: var(--greylight);
  margin-top: 8px;
}
.chat__info .info__wrapper {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}
.chat__picture {
  position: absolute;
  right: 70px;
  top: 0;
  height: 100%;
}
@media (max-width: 1023px) {
  .chat__picture {
    right: 35px;
  }
}
@media (max-width: 767px) {
  .chat__picture {
    position: relative;
    right: 0;
  }
}
.chat__picture .picture__img {
  height: 100%;
}

.price.price-widget {
  margin-top: 150px;
}
@media (max-width: 1440px) {
  .price.price-widget {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .price.price-widget {
    margin-top: 40px;
  }
}
.price.price-widget .tariff__wrapper {
  grid-template-rows: 46px repeat(2, 1fr);
}
@media (max-width: 767px) {
  .price.price-widget .tariff__wrapper {
    grid-template-rows: 34px 1fr 1fr;
  }
}
.price.price-widget .rate__wrapper {
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 767px) {
  .price.price-widget .rate__wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .hero.agr .hero__img {
    margin-left: 0;
  }
}

.userline .slider__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 42px;
  gap: 20px;
}
@media (max-width: 1023px) {
  .userline .slider__wrapper {
    display: flex;
    gap: 0;
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .userline .slider__wrapper {
    margin-top: 16px;
  }
}
.userline .slider__wrapper .slider__item {
  border-radius: 16px;
  padding: 40px;
  background-color: var(--lightmain);
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .userline .slider__wrapper .slider__item {
    padding: 24px;
    min-height: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  .userline .slider__wrapper .slider__item {
    padding: 16px;
  }
}
.userline .slider__wrapper .slider__item .item__step {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--main);
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .userline .slider__wrapper .slider__item .item__step {
    margin-top: 16px;
  }
}
.userline .slider__wrapper .slider__item .item__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin: 3px 0 0 0;
}
@media (max-width: 1279px) {
  .userline .slider__wrapper .slider__item .item__subtitle {
    font-size: 16px;
    margin-top: 16px;
    height: 42px;
  }
}
@media (max-width: 767px) {
  .userline .slider__wrapper .slider__item .item__subtitle {
    height: 83px;
  }
}

.solution.solution-agregator {
  margin-top: 40px;
}
.solution.solution-agregator.solution-bottom .solution__text {
  max-width: 520px;
}
.solution.solution-agregator.solution-bottom .solution__title {
  margin-bottom: 20px;
}
.solution.solution-agregator .solution__wrapper {
  gap: 24px;
  grid-template-columns: 620px 192px;
  justify-content: space-between;
  align-content: center;
}
@media (max-width: 1023px) {
  .solution.solution-agregator .solution__wrapper {
    grid-template-columns: 1fr 192px;
  }
}
@media (max-width: 767px) {
  .solution.solution-agregator .solution__wrapper {
    grid-template-columns: 1fr;
  }
}
.solution.solution-agregator .solution__wrapper .solution__title .blue {
  color: var(--main);
}
.solution.solution-agregator .solutions__container {
  display: flex;
  align-items: center;
}

.consultant__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 21px;
}
@media (max-width: 1023px) {
  .consultant__wrapper {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .consultant__wrapper {
    display: grid;
    gap: 3px;
  }
}
.consultant__img {
  object-fit: contain;
}
@media (max-width: 1279px) {
  .consultant__img {
    flex: 0 0 480px;
    width: 480px;
  }
}
@media (max-width: 767px) {
  .consultant__img {
    margin-top: 40px;
  }
}
.consultant__info {
  flex: 0 0 480px;
  height: min-content;
  margin-top: 76px;
}
@media (max-width: 1023px) {
  .consultant__info {
    margin-top: 0;
    flex: 0 0 100%;
  }
}
.consultant__info .consultant__conversion {
  display: flex;
  align-items: center;
  padding: 27px 35px;
  border: 2px dashed #b0d1ff;
  border-radius: 16px;
  margin-top: 40px;
  justify-content: space-between;
  gap: 24px;
}
@media (max-width: 767px) {
  .consultant__info .consultant__conversion {
    font-size: 18px;
    display: grid;
    gap: 12px;
    padding: 22px 20px;
  }
}
.consultant__info .consultant__conversion .conversion__percent {
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  color: var(--main);
}
.consultant__info .consultant__conversion .conversion__subtitle {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
}
@media (max-width: 1023px) {
  .consultant__info .consultant__conversion .conversion__subtitle {
    font-size: 18px;
  }
}
.consultant__logo {
  font-weight: 900;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.consultant__logo span {
  color: var(--main);
}
.consultant__title {
  margin-top: 12px;
}
.consultant__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 12px;
}
@media (max-width: 767px) {
  .consultant__text {
    font-size: 16px;
  }
}
.consultant__btn {
  margin-top: 32px;
  max-width: fit-content;
}
@media (max-width: 767px) {
  .consultant__btn {
    margin-top: 24px;
  }
}

.connection__btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 32px;
}
.connection__btns .btn {
  margin: 0;
}

@media (max-width: 767px) {
  .advantages.advantages-agregator .tasks__wrapper {
    grid-template-columns: 1fr;
  }
}
.advantages.advantages-agregator .tasks__wrapper .tasks__item:nth-child(1) .item__subtitle {
  max-width: 100%;
}
.advantages.advantages-agregator .advantages__column.column-analytics {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 24px;
}
@media (max-width: 1023px) {
  .advantages.advantages-agregator .advantages__column.column-analytics {
    flex-direction: column;
    gap: 0;
  }
}
.advantages.advantages-agregator .advantages__column.column-analytics .item__picture {
  flex: 0 0 517px;
}
@media (max-width: 1279px) {
  .advantages.advantages-agregator .advantages__column.column-analytics .item__picture {
    flex: 0 0 455px;
  }
}
@media (max-width: 1023px) {
  .advantages.advantages-agregator .advantages__column.column-analytics .item__picture {
    flex: 0 0 100%;
  }
}

.plan__title {
  text-align: center;
}
@media (max-width: 767px) {
  .plan__title {
    text-align: left;
  }
}
.plan__text {
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  margin-top: 16px;
}
@media (max-width: 1023px) {
  .plan__text {
    font-size: 18px;
    margin-top: 8px;
  }
}
@media (max-width: 767px) {
  .plan__text {
    text-align: left;
  }
}
.plan__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .plan__wrapper {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 24px;
    border: 1px solid var(--greybtn);
    padding: 24px;
    border-radius: 8px;
  }
}
.plan__item {
  padding: 144px 24px 24px 24px;
  border: 1px solid var(--greybtn);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  background-image: url("data:image/svg+xml,%3Csvg width='61' height='60' viewBox='0 0 61 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.332031' width='60' height='60' rx='30' fill='%230260E8'/%3E%3Crect x='14.332' y='14' width='32' height='32' rx='16' fill='%230260E8'/%3E%3Cpath d='M40.9974 22L26.3307 36.6667L19.6641 30' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 24px 24px;
}
@media (max-width: 1279px) {
  .plan__item {
    padding-top: 84px;
    background-size: 40px;
  }
}
@media (max-width: 767px) {
  .plan__item {
    border: 0;
    background-image: none;
    padding: 0;
    text-align: center;
  }
}
.plan__item .item__text {
  margin: 0;
  height: 58px;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #101828;
}
@media (max-width: 1279px) {
  .plan__item .item__text {
    font-size: 18px;
  }
}
@media (max-width: 1023px) {
  .plan__item .item__text {
    height: 76px;
  }
}
@media (max-width: 767px) {
  .plan__item .item__text {
    height: auto;
  }
}
.plan__btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 40px auto 0;
}
.plan__btn {
  display: block;
}

.form.form-agregator {
  margin-top: 150px;
}
@media (max-width: 1440px) {
  .form.form-agregator {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .form.form-agregator {
    margin-top: 40px;
  }
}

@media (max-width: 1023px) {
  .hero.hero-bot {
    margin-top: 60px;
  }
}
.hero.hero-bot .hero__info {
  flex: 0 0 497px;
}
@media (max-width: 1440px) {
  .hero.hero-bot .hero__info {
    flex: 0 0 410px;
  }
}
@media (max-width: 767px) {
  .hero.hero-bot .hero__info {
    display: contents;
  }
}
.hero.hero-bot .hero__title {
  font-weight: 500;
  font-size: 38px;
  line-height: 120%;
  color: #0f1010;
}
@media (max-width: 1440px) {
  .hero.hero-bot .hero__title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .hero.hero-bot .hero__title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .hero.hero-bot .hero__logo {
    display: none;
  }
}
@media (max-width: 1023px) {
  .hero.hero-bot .hero__btn {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .hero.hero-bot .hero__btn {
    width: 100%;
    justify-content: center;
    order: 1;
  }
}

.works__title {
  text-align: center;
}
.works__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .works__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .works__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 6px;
  }
}
.works__item {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .works__item {
    display: contents;
  }
}
.works__item .item__step {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #0260e8;
}
@media (max-width: 767px) {
  .works__item .item__step {
    border: 1px solid #e0e2e2;
    border-radius: 30px;
    font-weight: 400;
    color: var(--greylight);
    text-align: center;
    padding: 6px;
    text-transform: capitalize;
  }
}
@media (max-width: 767px) {
  .works__item .item__step.choose {
    color: var(--main);
    background-color: var(--lightmain);
    border: 1px solid var(--lightmain);
  }
}
.works__item .item__info {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #101828;
  height: 84px;
  margin-top: 4px;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .works__item .item__info {
    margin-bottom: 10px;
    height: 64px;
  }
}
@media (max-width: 767px) {
  .works__item .item__info {
    height: auto;
  }
}
.works__item .item__img {
  margin-top: auto;
}
@media (max-width: 767px) {
  .works__item .item__wrapper {
    display: none;
    grid-area: 2/1/3/5;
  }
}
@media (max-width: 767px) {
  .works__item .item__wrapper.active {
    display: block;
  }
}

@media (max-width: 1023px) {
  .plan.plan-bot {
    background-color: var(--bggrey);
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .plan.plan-bot {
    padding: 40px 0;
    margin: 0;
  }
}
.plan.plan-bot .plan__wrapper {
  margin-top: 24px;
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 1023px) {
  .plan.plan-bot .plan__wrapper {
    grid-template-columns: 1fr;
    align-items: center;
    text-align: center;
    border: 1px solid var(--greybtn);
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .plan.plan-bot .plan__wrapper {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .plan.plan-bot .plan__wrapper .plan__item {
    padding: 0;
    background-image: none;
    justify-content: center;
    border: none;
  }
}
@media (max-width: 1023px) {
  .plan.plan-bot .plan__wrapper .plan__item .item__text {
    height: auto;
    font-weight: 400;
    font-size: 16px;
  }
}
.plan .plan__time {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .plan .plan__time {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.plan .plan__time .plan__item {
  padding: 10px;
  font-size: 20px;
  line-height: 130%;
  color: #0f1010;
  text-align: center;
  background-image: none;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .plan .plan__time .plan__item {
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    border: 2px solid #edf4ff;
  }
}
.plan .plan__time .plan__item.item-blue {
  color: var(--main);
}
.plan .plan__time .plan__item.item-blue .item__text {
  color: var(--main);
}
.plan .plan__time .plan__item .item__month {
  font-weight: 500;
}
.plan .plan__time .plan__item .item__text {
  height: auto;
  margin-top: 4px;
  font-weight: 400;
}

.oferta__tabs {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.oferta .tab {
  display: none;
  font-size: 16px;
}
.oferta .tab.active {
  display: block;
}
.oferta .single-events__title {
  text-align: center;
}
.oferta p {
  margin: 10px 0;
}
.oferta .section-title {
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
}
.oferta .sub-section-title {
  font-weight: bold;
  margin-top: 15px;
  font-size: 1.1em;
}
.oferta .term-definition {
  margin-left: 20px;
}

.main._widget.comapre-header #canihave_modal_close {
  width: 28px !important;
  height: 28px !important;
}

.main._widget.comapre-header #canihave_modal_reload {
  margin-right: 25px;
}

.page__body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: var(--main-color);
}
@media (max-width: 1023px) {
  .page__body {
    font-size: 14px;
  }
}
.page__body.no-scroll {
  overflow: hidden;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  margin: 0;
}
@media (max-width: 1440px) {
  h1 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 24px;
  }
}

.title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  margin: 0;
}
@media (max-width: 1440px) {
  .title {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 20px;
  }
}
.title .blue {
  color: var(--main);
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin: 0 0 14px 0;
}
@media (max-width: 1440px) {
  .subtitle {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .subtitle {
    font-size: 16px;
  }
}

.btn {
  padding: 14px 24px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  background: transparent;
  text-decoration: none;
  line-height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
@media (max-width: 767px) {
  .btn {
    padding: 14px 20px;
  }
}
.btn.white {
  border: 1px solid var(--greybtn);
  color: var(--text);
  background-color: #fff;
}
.btn.white:hover {
  background-color: #fbfbfb;
}
.btn.blue {
  border: 1px solid var(--main);
  color: #fff;
  background-color: var(--main);
}
.btn.blue:hover {
  background: #085dd8;
  border: 1px solid #085dd8;
}
.btn.trans {
  border: 1px solid var(--greybtn);
  color: var(--text);
}
.btn.trans:hover {
  background-color: #fbfbfb;
}

a {
  font-weight: 500;
  line-height: 130%;
  color: var(--main);
  text-decoration: none;
}

section {
  margin-top: 150px;
}
@media (max-width: 1440px) {
  section {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  section {
    margin-top: 40px;
  }
}