.hero.agr {
  .hero__info {

  }
  .hero__img {
    @include mobile {
      margin-left: 0;
    }
  }
}
.userline {
  .slider__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 42px;
    gap: 20px;
    @include small-tablet {
      display: flex;
      gap: 0;
      margin-top: 24px;
    }
    @include mobile {
      margin-top: 16px;
    }
    .slider__item {
      border-radius: 16px;
      padding: 40px;
      background-color: var(--lightmain);
      box-sizing: border-box;
      @include small-tablet {
        padding: 24px;
        min-height: 100%;
        height: auto;
      }
      @include mobile {
        padding: 16px;
      }
      .item__step {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-transform: uppercase;
        color: var(--main);
        margin-top: 40px;
        @include small-tablet {
          margin-top: 16px;
        }
      }
      .item__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        margin: 3px 0 0 0;
        @include medium-tablet {
          font-size: 16px;
          margin-top: 16px;
          height: 42px;
        }
        @include mobile {
          height: 83px;
        }
      }
    }
  }
}
.solution.solution-agregator {
  margin-top: 40px;
  &.solution-bottom {
    .solution__text {
      max-width: 520px;
    }
    .solution__title {
      margin-bottom: 20px;
    }
  }
  .solution__wrapper {
    gap: 24px;
    grid-template-columns: 620px 192px;
    justify-content: space-between;
    align-content: center;
    @include small-tablet {
      grid-template-columns: 1fr 192px;
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
    .solution__title .blue {
      color: var(--main)
    }
  }
  .solutions__container {
    display: flex;
    align-items: center;
  }
}
.consultant {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 21px;
    @include small-tablet {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
    @include mobile {
      display: grid;
      gap: 3px;
    }
  }
  &__img {
    object-fit: contain;
    @include medium-tablet {
      flex: 0 0 480px;
      width: 480px;
    }
    @include mobile {
      margin-top: 40px;
    }
  }
  &__info {
    flex: 0 0 480px;
    height: min-content;
    margin-top: 76px;
    @include medium-tablet {

    }
    @include small-tablet {
      margin-top: 0;
      flex: 0 0 100%;
    }
    .consultant__conversion {
      display: flex;
      align-items: center;
      padding: 27px 35px;
      border: 2px dashed #b0d1ff;
      border-radius: 16px;
      margin-top: 40px;
      justify-content: space-between;
      gap: 24px;
      @include mobile {
        font-size: 18px;
        display: grid;
        gap: 12px;
        padding: 22px 20px;
      }
      .conversion__percent {
        font-weight: 800;
        font-size: 40px;
        line-height: 130%;
        color: var(--main);
      }
      .conversion__subtitle {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        @include small-tablet {
          font-size: 18px;
        }
      }
    }
  }
  &__logo {
    font-weight: 900;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    span {
      color: var(--main)
    }
  }
  &__title {
    margin-top: 12px;
  }
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    margin-top: 12px;
    @include mobile {
      font-size: 16px;
    }
  }
  &__btn {
    margin-top: 32px;
    max-width: fit-content;
    @include mobile {
      margin-top: 24px;
    }
  }
}
.connection {
  &__btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 32px;
    .btn {
      margin: 0;
    }
  }
}
.advantages.advantages-agregator {
  .tasks__wrapper {
    @include mobile {
      grid-template-columns: 1fr;
    }
    .tasks__item:nth-child(1) .item__subtitle {
      max-width: 100%;
    }
  }
  .advantages__column.column-analytics {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 24px;
    @include small-tablet {
      flex-direction: column;
      gap: 0;
    }
    .item__picture {
      flex: 0 0 517px;
      @include medium-tablet {
        flex: 0 0 455px;
      }
      @include small-tablet {
        flex: 0 0 100%;
      }
    }
  }
}
.plan {
  &__title {
    text-align: center;
    @include mobile {
      text-align: left;
    }
  }
  &__text {
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-top: 16px;
    @include small-tablet {
      font-size: 18px;
      margin-top: 8px;
    }
    @include mobile {
      text-align: left;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 40px;
    @include mobile {
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 24px;
      border: 1px solid var(--greybtn);
      padding: 24px;
      border-radius: 8px;
    }
  }
  &__item {
    padding: 144px 24px 24px 24px;
    border: 1px solid var(--greybtn);
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    background-image: url("data:image/svg+xml,%3Csvg width='61' height='60' viewBox='0 0 61 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.332031' width='60' height='60' rx='30' fill='%230260E8'/%3E%3Crect x='14.332' y='14' width='32' height='32' rx='16' fill='%230260E8'/%3E%3Cpath d='M40.9974 22L26.3307 36.6667L19.6641 30' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 24px 24px;
    @include medium-tablet {
      padding-top: 84px;
      background-size: 40px;
    }
    @include mobile {
      border: 0;
      background-image: none;
      padding: 0;
      text-align: center;
    }
    .item__text {
      margin: 0;
      height: 58px;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #101828;
      @include medium-tablet {
        font-size: 18px;
      }
      @include small-tablet {
        height: 76px;
      }
      @include mobile {
        height: auto;
      }
    }
  }
  &__btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 40px auto 0;
  }
  &__btn {
    display: block;

  }
}
.form.form-agregator {
  margin-top: 150px;
  @include tablet {
    margin-top: 100px;
  }
  @include mobile {
    margin-top: 40px;
  }
}
