@mixin burger {
  $bar-width: 30px;
  $bar-height: 3px;
  $bar-spacing: 10px;
  width: $bar-width;
  height: 27px;
  cursor: pointer;
  background: transparent;
  border: none;
  position: relative;
  padding: 0;


  .burger__line, .burger__line:after, .burger__line:before {
    width: $bar-width;
    height: $bar-height;
  }

  .burger__line {
    position: relative;
    transform: translateY($bar-spacing);
    background: var(--greylight);
    transition: all 0ms 300ms;
    display: block;
    border-radius: 3px;
    &.animate {
      background: transparent;
    }
  }

  .burger__line:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    border-radius: 3px;
    background: var(--greylight);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .burger__line:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    border-radius: 3px;
    background: var(--greylight);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .burger__line.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .burger__line.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}
