.header {
  width: 100%;
  height: 85px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--underline);
  background-color: #fff;
  z-index: 100;
  @include mobile {
    height: 68px;
  }
  &.active {
    border: none;
  }
  &__wrapper {
    max-width: 1440px;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;
    @include tablet {
      padding: 0 15px;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include small-tablet {
      transform: scaleY(0);
      transform-origin: top;
      position: fixed;
      width: 100%;
      left: 0;
      top: 85px;
      //height: calc(100% - 85px);
      background-color: #fff;
      padding: 24px 15px;
      border-bottom: 1px solid var(--underline);
      transition: .3s;
    }
    @include mobile {
      height: calc(100% - 68px);
      top: 68px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &.active {
      transform: scaleY(1);
    }
    .menu__list {
      display: flex;
      gap: 22px;
      @include small-tablet {
        flex-direction: column;
        gap: 24px;
      }
      .list__item {
        &.item-sub {
          svg {
            margin: 0 0 3px 4px ;
            transition: .3s;
            @include small-tablet {
              display: none;
            }
          }
          &.active {
            .item__submenu {
              visibility: visible;
              transform: scaleY(1);
            }
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .item__link {
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: var(--text);
          padding: 0 3px;
          @include small-tablet {
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
          }
          &:hover {
            color: var(--main);
          }
          &.active {
            font-weight: 600;
          }
        }
        .item__submenu {
          transform: scaleY(0);
          transform-origin: top;
          visibility: hidden;
          position: absolute;
          border: 1px solid var(--underline);
          border-radius: 10px;
          background-color: #fff;
          padding: 20px 24px;
          box-shadow: 2px 5px 13px 0 rgba(12, 32, 60, 0.06);
          margin-top: 15px;
          transition: .3s;
          @include small-tablet {
            visibility: visible;
            transform: scaleY(1);
            position: relative;
            border: none;
            margin-top: 0;
            padding-bottom: 0;
            box-shadow: none;
          }
          .submenu__list {
            display: grid;
            gap: 12px;
            @include small-tablet {
              gap: 16px;
            }
            .list__item {
              .item__link {
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                @include small-tablet {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    .menu__wrapper {
      display: flex;
      gap: 16px;
      align-items: center;
      @include mobile {
        margin-top: auto;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
      .btn {
        @include mobile {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__burger {
    display: none;
    @include small-tablet {
      @include burger();
      display: flex;
      align-items: flex-start;
      margin-left: auto;
    }
  }
}
