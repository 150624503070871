.hero.hero-bot {
  @include small-tablet {
    margin-top: 60px;
  }
  .hero__info {
    flex: 0 0 497px;
    @include tablet {
      flex: 0 0 410px;
    }
    @include mobile {
      display: contents;
    }
  }
  .hero__title {
    font-weight: 500;
    font-size: 38px;
    line-height: 120%;
    color: #0f1010;
    @include tablet {
      font-size: 30px;
    }
    @include mobile {
      font-size: 22px;
    }
  }
  .hero__logo {
    @include mobile {
      display: none;
    }
  }
  .hero__btn {
    @include small-tablet {
      margin-top: 16px;
    }
    @include mobile {
      width: 100%;
      justify-content: center;
      order: 1;
    }
  }
}
.works {
  &__title {
    text-align: center;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 40px;
    @include small-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px 6px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    @include mobile {
      display: contents;
    }
    .item {
      &__step {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-transform: uppercase;
        color: #0260e8;
        @include mobile {
          border: 1px solid #e0e2e2;
          border-radius: 30px;
          font-weight: 400;
          color: var(--greylight);
          text-align: center;
          padding: 6px;
          text-transform: capitalize;
        }
        &.choose {
          @include mobile {
            color: var(--main);
            background-color: var(--lightmain);
            border: 1px solid var(--lightmain);
          }
        }
      }
      &__info {
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #101828;
        height: 84px;
        margin-top: 4px;
        margin-bottom: 20px;
        @include small-tablet {
          margin-bottom: 10px;
          height: 64px;
        }
        @include mobile {
          height: auto;
        }
      }
      &__img {
        margin-top: auto;
      }
      &__wrapper {
        @include mobile {
          display: none;
          grid-area: 2 / 1 / 3 / 5;
        }
        &.active {
          @include mobile {
            display: block;
          }
        }
      }
    }
  }
}
.plan {
  &.plan-bot {
    @include small-tablet {
      background-color: var(--bggrey);
      padding-bottom: 60px;
    }
    @include mobile {
      padding: 40px 0;
      margin: 0;
    }
    .plan__wrapper {
      margin-top: 24px;
      grid-template-columns: repeat(5, 1fr);
      @include small-tablet {
        grid-template-columns: 1fr;
        align-items: center;
        text-align: center;
        border: 1px solid var(--greybtn);
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        gap: 16px;
      }
      @include mobile {
        width: 100%;
      }
      .plan__item {
        @include small-tablet {
          padding: 0;
          background-image: none;
          justify-content: center;
          border: none;
        }
        @include mobile {

        }
        .item__text {
          @include small-tablet {
            height: auto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
  }
  .plan__time {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 24px;
    margin-top: 40px;
    @include mobile {
      grid-template-columns: 1fr;
      gap: 16px;
    }
    .plan__item {
      padding: 10px;
      font-size: 20px;
      line-height: 130%;
      color: #0f1010;
      text-align: center;
      background-image: none;
      flex-direction: column;
      align-items: center;
      @include mobile {
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        border: 2px solid #edf4ff;
      }
      &.item-blue {
        color: var(--main);
        .item__text {
          color: var(--main);
        }
      }
      .item__month {
        font-weight: 500;
      }
      .item__text {
        height: auto;
        margin-top: 4px;
        font-weight: 400;
      }
    }
  }
}
