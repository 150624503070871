.hero {
  &__wrapper {
    @include mobile {
      gap: 16px;
    }
  }
  &__img {
    @include mobile {
      margin-left: -15px;
    }
  }
}

.recommends {
  .slider__wrapper {
    margin-top: 42px;
    width: 100%;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include small-tablet {
      gap: 0;
      margin-top: 24px;
    }
    @include mobile {
      margin-top: 16px;
    }
    .slider__item {
      flex: 0 0 280px;
      padding: 24px 24px 45px 24px;
      border-radius: 8px;
      border: 1px solid var(--lightmain);
      box-sizing: border-box;
      display: grid;
      justify-content: space-between;
      grid-template-rows: 1fr 134px;
      @include medium-tablet {
        padding: 16px 16px 20px 16px;
      }
      @include small-tablet {
        padding-bottom: 16px;
      }
      &:last-child {
      padding: 0 24px 45px 24px;
        @include medium-tablet {
          padding: 0 16px 20px 16px;
        }
        @include small-tablet {
          padding-bottom: 16px;
        }
      }
      .item {
        &__info {
          height: 134px;
        }
        &__subtitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 139%;
          margin: 24px 0 0 0;
          @include medium-tablet {
            font-size: 16px;
          }
        }
        &__text {
          margin-top: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          @include medium-tablet {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.try {
  background-color: var(--lightmain);
  padding: 80px 0 80px;
  text-align: center;
  @include small-tablet {
    padding: 60px 0 60px;
  }
  @include mobile {
    text-align: left;
    padding: 40px 0 40px 0;
  }
  &__info {
    width: 785px;
    margin: 0 auto;
    @include small-tablet {
      width: 100%;
      padding: 0 var(--container-offset);
    }
    .info__logo {
      font-weight: 900;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--grey);
      @include mobile {
        text-align: center;
      }
      .blue {
        color: var(--main)
      }
    }
    .info__title {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      color: var(--text);
      margin: 12px 0 0 0;
      @include small-tablet {
        font-size: 24px;
        line-height: 120%;
        margin: 12px auto 0 auto;
        max-width: 515px;
      }
      @include mobile {
        font-size: 20px;
        text-align: center;
      }
    }
    .info__text {
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      margin-top: 14px;
      @include small-tablet {
        font-size: 16px;
      }
      @include mobile {
        display: none;
      }
    }
    .info__container {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin-top: 32px;
      @include mobile {
        margin-top: 24px;
      }
    }
  }
}

.effect.effect-widget {
  .effect__title {
    @include mobile {
      width: 280px;
    }
  }
  .effect__wrapper.wrapper-list .effect__list {
    gap: 12px;
    .list__item {
      .item__icon {
        @include mobile {
          display: block;
        }
      }

      .item__subtitle {
        @include mobile {
          margin-top: 16px;
        }
      }
    }
  }
}
.solution.solution-widget {
  .solution__title {
    font-style: italic;
    font-size: 20px;
    @include small-tablet {
      font-size: 16px;
    }
    .title__author {
      display: block;
      margin-top: 20px;
      @include small-tablet {
        margin-top: 16px;
      }
    }
  }
}
.widget {
  .slider__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 580px 580px;
    gap: 20px;
    margin-top: 42px;
    @include small-tablet {
      display: flex;
      gap: 0;
      margin-top: 24px;
    }
    @include mobile {
      margin-top: 16px;
    }
    .slider__item {
      border-radius: 16px;
      padding: 40px;
      background-color: var(--lightmain);
      box-sizing: border-box;
      display: grid;
      align-content: space-between;
      @include small-tablet {
        padding: 24px;
        min-height: 100%;
        height: auto;
      }
      @include mobile {
        padding: 16px;
      }
      .item__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        margin: 0 0 3px 0;
        @include medium-tablet {
          font-size: 16px;
          margin-top: 16px;
          height: 42px;
        }
        @include mobile {
          height: 83px;
        }
      }
    }
  }
}
.tasks.tasks-widget {
  .tasks__wrapper {
    grid-template-columns: repeat(3, 1fr);
    @include mobile {
      grid-template-columns: 1fr;
    }
    .tasks__item:nth-child(1) .item__subtitle {
      max-width: 100%;
    }
  }
  .advantages {
    &__column.column-opportunity {
      grid-template-columns: 1fr;
      .opportunity__item {
        display: block;

        .item__subtitle {
          margin-top: 21px;
        }
      }
    }
    &__column.column-analytics .item__picture {
      @include small-tablet {
        margin-top: 9px;
      }
    }
  }
}
.connection {
  background-color: var(--bggrey);
  padding: 80px 0;
  @include small-tablet {
    padding: 60px 0;
  }
  @include mobile {
    padding: 40px 0 24px;
  }
  &__title {
    text-align: center;
    @include mobile {
      text-align: left;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 32px;
    @include medium-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 24px;
    }
    .list__item {
      border: 1px solid var(--greybtn);
      border-radius: 8px;
      padding: 24px;
      .item__subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        color: var(--main);
      }
      .item__text {
        font-weight: 600;
        font-size: 18px;
        line-height: 139%;
        text-align: center;
        color: #101828;
        margin-top: 4px;
        display: block;
        width: 100%;
        @include mobile {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
  &__btn {
    margin: 32px auto 0;
    @include small-tablet {
      margin-top: 24px;
    }
  }
}
.chat {
  margin-top: 50px;
  padding-top: 100px;
  @include tablet {
    margin-top: 0;
  }
  @include small-tablet {
    padding-top: 60px;
  }
  &__wrapper {
    position: relative;
    height: 675px;
    display: flex;
    align-items: center;
    @include medium-tablet {
      height: 580px;
    }
    @include small-tablet {
      height: 460px;
    }
    @include mobile {
      height: auto;
      background-color: var(--lightmain);
      flex-direction: column;
      padding-bottom: 24px;
    }

  }
  &__info {
    background-color: var(--lightmain);
    padding: 70px 90px;
    width: 100%;
    border-radius: 16px;
    @include small-tablet {
      padding: 40px 35px;
    }
    @include mobile {
      padding: 40px 0;
    }
    .info {
      &__title, &__text {
        max-width: 655px;
        font-size: 24px;
        @include medium-tablet {
          font-size: 20px;
          max-width: 530px;
        }
        @include small-tablet {
          font-size: 18px;
          max-width: 445px;
        }
      }
      &__title {
        color: var(--text);
        margin: 0;
      }
      &__text {
        line-height: 130%;
        color: var(--greylight);
        margin-top: 8px;
      }
      &__wrapper {
        margin-top: 24px;
        display: flex;
        gap: 16px;
      }
    }
  }
  &__picture {
    position: absolute;
    right: 70px;
    top: 0;
    height: 100%;
    @include small-tablet {
      right: 35px;
    }
    @include mobile {
      position: relative;
      right: 0;
    }
    .picture__img {
      height: 100%;
    }
  }
}
.price.price-widget {
  margin-top: 150px;
  @include tablet {
    margin-top: 100px;
  }
  @include mobile {
    margin-top: 40px;
  }
  .tariff__wrapper {
    grid-template-rows: 46px repeat(2, 1fr);
    @include mobile {
      grid-template-rows: 34px 1fr 1fr;
    }
  }
  .rate {
    &__wrapper {
      grid-template-columns: 1fr 1fr;
      @include mobile {
        grid-template-columns: 1fr;
      }
    }
  }
}
