
.oferta {
  &__tabs {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .tab {
    display: none;
    font-size: 16px;
    &.active {
      display: block;
    }
  }
  .single-events__title {
    text-align: center;
  }
  p {
    margin: 10px 0;
  }
  .section-title {
    font-weight: bold;
    margin-top: 20px;
    font-size: 1.2em;
    text-align: center;
  }
  .sub-section-title {
    font-weight: bold;
    margin-top: 15px;
    font-size: 1.1em;
  }
  .term-definition {
    margin-left: 20px;
  }
}
.main._widget.comapre-header #canihave_modal_close {
  width: 28px!important;
  height: 28px!important;
}
.main._widget.comapre-header #canihave_modal_reload {
  margin-right: 25px;
}

