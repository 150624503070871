// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";
@import "macro";


// подключения компонентов страницы
@import "./components/header";
@import "components/footer";
@import "components/sections";
@import "components/widget";
@import "components/agregator";
@import "components/bot";
@import "components/oferta";

.page__body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: var(--main-color);
  @include small-tablet {
    font-size: 14px;
  }
  &.no-scroll {
    overflow: hidden;
  }
}
h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  margin: 0;
  @include tablet {
    font-size: 32px;
  }
  @include mobile {
    font-size: 24px;
  }
}
.title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  margin: 0;
  @include tablet {
    font-size: 26px;
  }
  @include mobile {
    font-size: 20px;
  }
  .blue {
    color: var(--main)
  }
}
.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin: 0 0 14px 0;
  @include tablet {
    font-size: 20px;
  }
  @include mobile {
    font-size: 16px;
  }
}
.btn {
  padding: 14px 24px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  background: transparent;
  text-decoration: none;
  line-height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  @include mobile {
    padding: 14px 20px;
  }
  &.white {
    border: 1px solid var(--greybtn);
    color: var(--text);
    background-color: #fff;
    &:hover {
      background-color: #fbfbfb;
    }
  }
  &.blue {
    border: 1px solid var(--main);
    color: #fff;
    background-color: var(--main);
    &:hover {
      background: #085dd8;
      border: 1px solid #085dd8;
    }
  }
  &.trans {
    border: 1px solid var(--greybtn);
    color: var(--text);
    &:hover {
      background-color: #fbfbfb;
    }
  }
}
a {
  font-weight: 500;
  line-height: 130%;
  color: var(--main);
  text-decoration: none;
}
section {
  margin-top: 150px;
  @include tablet {
    margin-top: 100px;
  }
  @include mobile {
    margin-top: 40px;
  }
}
